<template>
  <!-- 网站版权信息去 -->
  <div>
    <div class="copyright">
      <!-- 上部分 start -->
      <div class="upper-part">
        <img
          @click="$router.push({ name: 'Home' })"
          style="width: 188px; height: 43px; object-fit: cover; image-rendering: -webkit-optimize-contrast; cover;cursor: pointer;"
          v-imgLazy="logoBottom"
          alt="中天云网"
        />
        <!-- 上 中 start -->
        <div class="part-center">
          <ul style="display: flex">
            <li class="nav-item" v-for="item in navList" :key="item.name">
              <a
                href="javascript:;"
                style="color: #eee; text-decoration: none"
                @click="toPage(item)"
                >{{ item.meta.title }}</a
              >
            </li>
          </ul>

          <div class="info">
            <span style="margin-bottom: 16px;">公司电话：0351-7776777</span>
            <span style="margin-bottom: 16px;">邮箱：18803517272@139.com</span>
            <span>地址：山西省太原市小店区南内环街华邦国际</span>
          </div>
        </div>
        <!-- 上 中 end -->

        <!-- 上 右 -->
        <div class="code">
          <img class="qr-code" alt="二维码" v-imgLazy="qrCode" />
          <span clss="color: #fff">copyright©山西中天云网科技有限公司</span>
        </div>
      </div>
      <!-- 上部分 end -->

      <!-- 友情链接 start -->
      <!-- <div class="links">
        <span class="tip">友情链接</span>
        <span v-for="(item, index) in linkList" :key="item.name"
          ><a
            class="link-item"
            style="color: #ABABAB; text-decoration: none"
            :href="item.href"
            >{{ item.name }}</a
          >
          <span class="spacing" v-if="index !== linkList.length - 1"
            >|</span
          ></span
        >
      </div> -->
      <!-- 友情链接 end -->
    </div>

    <!-- 版权信息 -->
    <div class="copyright-bottom">
      <span class="beian" @click="toBeiAn"
        >版权所有 2022-<span>{{ fullYear }}</span>
        山西中天云网科技有限公司 晋ICP备2023016129号-1</span
      >
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'

export default {
  name: 'Copyright',
  data() {
    return {
      fullYear: '',
      // 导航
      navList: [],
      // 友情连接
      linkList: [
        {
          name: '蓝鲸自动化运维',
          href: ''
        },
        {
          name: '机器视觉',
          href: ''
        },
        {
          name: '虚拟私有云VPC',
          href: ''
        },
        {
          name: 'Docker容器管理',
          href: ''
        },
        {
          name: '借贷反欺诈AF',
          href: ''
        },
        {
          name: '微服',
          href: ''
        }
      ],

      logoBottom: require('@/assets/images/logo-bottom.png'),
      qrCode: require('@/assets/images/erweima.png')
    }
  },

  mounted() {
    this.getRoutes()

    const date = new Date()

    this.fullYear = date.getFullYear() // 获取完整的年份(4位)
  },

  methods: {
    toPage(item) {
      // this.$EventBus.$emit('setActive', item.name)
      this.$router.push({ name: item.name })
    },

    // 获取路由
    getRoutes() {
      const route = []
      routes.forEach((item, index) => {
        if (index === 0) {
          return false
        }
        route.push(item.children[0])
      })
      this.navList = route
      // console.log(this.navList)
    },

    // 跳转备案号
    toBeiAn() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="scss" scoped>
.copyright {
  // height: 325px;
  height: 280px;
  color: #eee;
  box-sizing: border-box;
  background-color: #08467c;;
  padding-top: 60px;
}

.upper-part {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.part-center {
  margin: 0 60px;

  .nav-item {
    margin: 0 33px;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 33px;
    margin-top: 96px;
    color: #eee;
    line-height: 0.6;
  }
}

.links {
  text-align: center;

  .tip {
    margin-right: 34px;

    .link-item {
      text-decoration: none;
    }
  }
}

.code {
  display: flex;
  flex-direction: column;
  align-items: center;

  .qr-code {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }
}

.spacing {
  margin: 0 10px;
}

.copyright-bottom {
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #333;

  .beian {
    cursor: pointer;
  }
}
</style>
