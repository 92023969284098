<template>
  <!-- 联系我们 -->
  <div>
    <img class="img-bg" src="@/assets/images/lianxiwomen.png" alt="联系我们" />

    <ParagraphTitle title="联系我们" />

    <div class="container-w1200">
      <!-- 地址 start -->
      <div class="address">
        <div>
          <img src="@/assets/images/address.png" alt="地址" />
        </div>
        <div class="address-info">
          <span class="company-name">中天云网科技有限公司</span>
          <span class="company-address">公司地址：山西省太原市小店区南内环街华邦国际</span>
          <span>公司邮箱：18803517272@139.com</span>
        </div>
      </div>
      <!-- 地址 end -->

      <!-- 联系方式 start -->
      <div class="contact line">
        <div style="display: flex;">
          <img src="@/assets/images/cooperation.png" />
          <span class="contact-text">
            <span class="text1">商务合作</span>
            <span class="text2">专业业务咨询，合作共赢</span>
          </span>
        </div>
        <span class="contact-email">公司电话：0351-7776777</span>
      </div>
      <div class="contact">
        <div style="display: flex;">
          <img src="@/assets/images/weixin.png" />
          <span class="contact-text">
            <span class="text1">微信公众号</span>
            <span class="text2">详细中天云网资讯尽在掌握</span>
          </span>
        </div>
        <div>
          <img class="erweima" src="@/assets/images/erweima.png" />
        </div>
      </div>
    </div>

    <!-- 版权信息 -->
    <Copyright />
  </div>
</template>

<script>
import ParagraphTitle from '@/components/ParagraphTitle'
import Copyright from '@/components/Copyright'

export default {
  name: 'We',
  components: {
    ParagraphTitle,
    Copyright
  },

  data() {
    return {
      addressImg: require('@/assets/images/address.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.img-bg {
  width: 100%;
  height: 450px;
  object-fit: cover;
  margin-bottom: 55px;
}

.container-w1200 {
  margin: 67px auto 0;
}

.address {
  display: flex;
  justify-content: space-between;

  .address-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 40px;
    margin-left: 40px;
    color: #666;
    font-size: 16px;

    .company-name {
      font-size: 28px;
      font-weight: bold;
    }

    .company-address {
      margin: 52px 0 26px;
    }
  }
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 186px;

  .contact-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 28px;

    .text1 {
      font-size: 22px;
      font-weight: bold;
      color: #666;
      margin-bottom: 13px;
    }

    .text2 {
      font-size: 16px;
      color: #999;
    }
  }

  .contact-email {
    font-size: 24px;
    font-weight: bold;
    color: #666;
  }

  .erweima {
    width: 125px;
    object-fit: cover;
    border: 1px solid #eee;
  }
}

.line {
  border-bottom: 1px solid #e5e5e5;
}
</style>
